/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { Container, Box, Flex, NavLink } from '@theme-ui/components'
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { Grid, Divider, Button, Card, Text, Message} from '@theme-ui/components';
import NavBar from '../components/NavBar';
import { graphql } from 'gatsby';
import { Link as GatsbyLink } from 'gatsby'
import { Link } from '@theme-ui/components'
import Footer from '../components/footer';
import Seo from '../components/seo';
import { Alert } from 'theme-ui'


const index = ({ data }) => {
  const posts = data.allMdx.nodes
  console.log(posts);
  return (
    <div>
    <Seo />
    <Alert sx={{backgroundColor: '#ffcc00', color:'#444'}}>
      <Text sx={{fontWeight: 'bold'}}>Courses Coming Soon: (Summer 2022) Vibration Essentials 1 -- (Fall 2022) Ultrasound Essentials 2 - stay tuned</Text>
    </Alert>
      <NavBar />
      <div sx={{
      width: '100vw',
      minWidth: '100vw',
      maxWidth: '100vw',
      minHeight: '90vh',
      backgroundColor: 'primary',
      position: 'relative',
    }}>

    <StaticImage
            src="../images/ame-hero-v2a.png"
            alt=""
            objectFit="cover"
            objectPosition="100% 0%"
            style={{ position: "absolute", zIndex: 1, height: '100%', width: '100%', maxWidth: '100%' }}
        />

        <div sx={{
          backgroundColor: "background",
          opacity: 0.8,
        height: "100%",
        width: "100%",
        position: "absolute",
        zIndex: 2,
        }}
        >
        </div>

  
    <Container sx={{
      height: "100%",
        zIndex: 100,
        position: "relative",
    }}>

    <Box py={6} color="white">
    <Grid gap={2} columns={[2, '3fr 1fr']}>
    <Box>
    <h1
    sx={{
      color: 'white',
      fontSize: '48px',
      fontWeight: '500',
      lineHeight: '0.75',
    }}>
      <span sx={{
        color: 'secondary',
      }}>Empowering asset stakeholders</span><br /><span sx={{fontSize: '24px'}}>and their guardians, with quality, accessible, affordable training.</span>
    </h1>
    <Divider sx={{
      backgroundColor: 'primary',
    }}/>
    </Box>
      <Box></Box>
    </Grid>
    
    

    <h2>
    Elite manufacturers know the value of training personnel. AME Systems delivers affordable <span sx={{
        color: 'secondary',
      }}>asset management education</span> to the globe.
    </h2>
    <p>
    An asset is deemed reliable when it delivers its engineered value consistently for a determined length of time and required outages for maintenance are managed proactively. But if the guardians of these assets are not capable of anticipating failures, then proactive outages become reactive firefighting campaigns. Therefore, organizations must pursue a strategy that includes training personnel in all facets of asset condition management.
    </p>

    
    <Button as={GatsbyLink} to='courses' mr={2}>Preview all courses & content!</Button>

    <Container sx={{
        top: '95%',
        zIndex: 101,
        position: "absolute",
    }}>
    <Box py={6} color="white">
    <Grid gap={2} columns={[3, '1fr 1fr 1fr']}>
    <Box></Box>
    <Box sx={{mx: 'auto', fontSize: '36px', fontWeight: 600, opacity:0.6}}><Button as={GatsbyLink} to='/#scroll-to' mr={2}>&#x25BC;</Button></Box>
    <Box></Box>
    </Grid>
    </Box>
      
    </Container>
    </Box>
    </Container>
    </div>
    <Container id="scroll-to">
    <Box py={4}>
    <Text py={3} as="h2">
      New Releases
    </Text>
    <Grid columns={[1, 1, 3]}>

    {posts.map(post => {
        if(post.frontmatter.contentCategory === 'Course' && post.frontmatter.newrelease === true) {
          return (
            <Card key={post.slug}
              sx={{
                maxWidth: '100%',
                padding: 1,
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'primary',
              }}>
              <GatsbyImage image={getImage(post.frontmatter.featuredImage)}
              />
              <Text as="h3" p={2} sx={{
                color: 'secondary',
              }}>{post.frontmatter.title} - {post.frontmatter.subTitle}</Text>
              <Text as="p" p={2} sx={{
                color: 'text',
                fontSize: '12px',
              }}>{post.frontmatter.company}</Text>
              <Text as="p" p={2} sx={{
                color: 'text',
              }}>{post.frontmatter.excerpt}</Text>
                    <Link p={2} sx={{ color: 'primary' }} to={post.slug} as={GatsbyLink}>
        Read More...
      </Link>
              
              </Card>
          )
        }
        })}

        
    </Grid>
    <Divider my={4} sx={{
      backgroundColor: 'primary',
    }}/>
    <Text py={3} as="h2">
      Featured Courses
    </Text>
    <Grid columns={[1, 1, 3]}>

    {posts.map(post => {
        if(post.frontmatter.contentCategory === 'Course' && post.frontmatter.featured === true) {
          return (
            <Card key={post.slug}
              sx={{
                maxWidth: '100%',
                padding: 1,
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'primary',
              }}>
              <GatsbyImage image={getImage(post.frontmatter.featuredImage)}
              />
              <Text as="h3" p={2} sx={{
                color: 'secondary',
              }}>{post.frontmatter.title} - {post.frontmatter.subTitle}</Text>
              <Text as="p" p={2} sx={{
                color: 'text',
                fontSize: '12px',
              }}>{post.frontmatter.company}</Text>
              {post.frontmatter.message && <Message sx={{
                backgroundColor: 'background',
                borderColor: '#00ff00',
                color: 'text',
                marginBottom: '10px',
                borderTop: '1px solid #00ff00',
                borderBottom: '1px solid #00ff00',
                borderRight: '1px solid #00ff00',
                borderLeftWidth: '10px'}}>
                <Text as="p" sx={{
                  fontWeight: '700',
                  fontSize: '12px',
                  color: '#00ff00'
                }}>
                  {post.frontmatter.messages.map(info => {
                    return (
                      <>
                      {info.alertTitle} - {info.alertSubTitle} - {info.alertDate}
                      </>
                      )
                  })}
                </Text>
                <Divider my={1} sx={{
                  backgroundColor: '#00ff00',
                }}/>
                <Text as="p" sx={{
                  fontSize: '12px',
                }}>
                  {post.frontmatter.messages.map(info => {
                    return (
                      <>
                      {info.alertMessage}
                      </>
                      )
                  })}
                </Text>
                </Message>}
              <Text as="p" p={2} sx={{
                color: 'text',
              }}>{post.frontmatter.excerpt}</Text>
                    <Link p={2} sx={{ color: 'primary' }} to={post.slug} as={GatsbyLink}>
        Read More...
      </Link>
              
              </Card>
          )
        }
        })}

        
    </Grid>
    <Box py={4} mx={'auto'}>
    <Flex
    sx={{
      alignItems: 'center',
    }}>
    {/*<Button as={GatsbyLink} to='courses' mx={'auto'}>See All Courses!</Button>*/}
    </Flex>
    </Box>
    </Box>
    <Divider sx={{
      backgroundColor: 'primary',
    }}/>

<Box py={4}>
    <Text py={3} as="h2">
      Featured Technology Partners
    </Text>
    <Grid columns={[1, 1, 2]}>

    {posts.map(post => {
        if(post.frontmatter.contentCategory === 'Technology Partner') {
          return (
            <Card key={post.slug}
              sx={{
                maxWidth: '100%',
                padding: 1,
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'primary',
              }}>
              <GatsbyImage image={getImage(post.frontmatter.featuredImage)}
              />
              <Text as="h3" p={2} sx={{
                color: 'secondary',
              }}>{post.frontmatter.title} - {post.frontmatter.subTitle}</Text>
              <Text as="p" p={2} sx={{
                color: 'text',
                fontSize: '12px',
              }}>{post.frontmatter.company}</Text>
              {post.frontmatter.message && <Message sx={{
                backgroundColor: 'background',
                borderColor: '#00ff00',
                color: 'text',
                marginBottom: '10px',
                borderTop: '1px solid #00ff00',
                borderBottom: '1px solid #00ff00',
                borderRight: '1px solid #00ff00',
                borderLeftWidth: '10px'}}>
                <Text as="p" sx={{
                  fontWeight: '700',
                  fontSize: '12px',
                  color: '#00ff00'
                }}>
                  {post.frontmatter.messages.map(info => {
                    return (
                      <>
                      {info.alertTitle} - {info.alertSubTitle} - {info.alertDate}
                      </>
                      )
                  })}
                </Text>
                <Divider my={1} sx={{
                  backgroundColor: '#00ff00',
                }}/>
                <Text as="p" sx={{
                  fontSize: '12px',
                }}>
                  {post.frontmatter.messages.map(info => {
                    return (
                      <>
                      {info.alertMessage}
                      </>
                      )
                  })}
                </Text>
                </Message>}
              <Text as="p" p={2} sx={{
                color: 'text',
              }}>{post.frontmatter.excerpt}</Text>
                    <Link p={2} sx={{ color: 'primary' }} to={post.slug} as={GatsbyLink}>
        Read More...
      </Link>
              
              </Card>
          )
        }
        }
        )}
    

        
    </Grid>
    <Box py={4} mx={'auto'}>
    <Flex
    sx={{
      alignItems: 'center',
    }}>
    {/*<Button as={GatsbyLink} to='courses' mx={'auto'}>All Technology Partners!</Button>*/}
    </Flex>
    </Box>
    </Box>
    <Divider sx={{
      backgroundColor: 'primary',
    }}/>

{/*<Box py={4}>
    <Text py={3} as="h2">
      Featured Mentors
    </Text>
    
    <Box py={4} mx={'auto'}>
    <Flex
    sx={{
      alignItems: 'center',
    }}>
    <Button as={GatsbyLink} to='courses' mx={'auto'}>Meet All the AME Systems Mentors!</Button>
    </Flex>
    </Box>
    </Box>
    <Divider sx={{
      backgroundColor: 'primary',
    }}/>*/}

    <Text py={3} as="h2">
      About AME Systems
    </Text>
    <Text py={3} as="h3">
    We want to train the world to take better care of their assets.
    </Text>
    <Text py={3} as="p">
    AME Systems is a dynamic web-based learning system that aligns with our mission to make high-quality training and education accessible and affordable. Democratizing this exchange of knowledge levels the playing field by bringing world-renowned expert trainers right to your desktop, tablet, or mobile device. We will deliver on our promise to bring streaming content to corporate teams or individuals interested in mastering the many disciplines of asset management. 
    </Text>
    <Text py={3} as="p">
    It is not uncommon to associate asset management tasks with the work performed by the maintenance department and there is nothing wrong with that. But if we only think in terms of what the maintenance team brings to the table, then there is a 100% certainty that one day our assets will not deliver their expected value when called upon. Therefore asset stakeholders must view the management of their assets in a more inclusive sense.
    </Text>
    <Text py={3} as="p">
    The International Standards Organization (ISO) published a guide to asset management (ISO 55000) in which asset management is described as a set of collaborative, coordinated tasks that span the entire live cycle of the asset. These tasks do not belong to just the maintenance department; or just the purchasing department, or just the procurement team. They belong to everyone with a stake in the successful operation of the organization from the receptionist all the way up to the CEO.
    </Text>
    <Text py={3} as="p">
    Are you on that list? Then AME Systems is for you.
    </Text>
    </Container>
    <Footer />
    </div>
  )
}

export default index

export const pageQuery = graphql`
  query {
    allMdx(sort: {fields: [frontmatter___date], order: DESC}) {
      nodes {
        id
        slug
        excerpt(pruneLength: 250)
        frontmatter {
          category
          excerpt
          author
          company
          contentCategory
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
          title
          subTitle
          date(formatString: "YYYY MMMM Do")
          newrelease
          featured
          modules {
            moduleNumber
            title
            content
          }
          message
          messages {
            alertMessage
            alertTitle
            alertSubTitle
            alertDate
          }
          techPartnerOfferedCourses {
            techPartnerOfferedCourseLink
            techPartnerOfferedCourseTitle
          }
        }
      }
    }
  }
`
